import Icon from '@material-ui/core/Icon';
import React from 'react';
// import {  } from 'react';
import { useMemo, useEffect, useState, MouseEvent } from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getData, postData, deleteData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { isSadmin, isLayoutConfigEnabled } from '../../utils';
import Swal from 'sweetalert2'
import { logout } from "../../redux/actions/auth.action";
import * as XLSX from 'xlsx'
import "../pages/table.css"
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from "@tanstack/react-table";
  


function Devices(props: any) {
    const [validationMsg, setValidationMsg] = useState<any>("");
    const [deviceList, setDeviceList] = useState<any>([]);
    const [deviceName, setDeviceName] = useState<any>("");
    const [deviceMake, setDeviceMake] = useState<any>("");
    const [layoutErrorMsg, setLayoutErrorMsg] = useState<any>("");
    const [downloadList, setdownloadList] = useState<any>([]);

    const [makeList, setMakeList] = useState<any>({});
    const [show, setShow] = useState(false);
    const [showConfig, setShowConfig] = useState(false);
    const [currentDevice, setCurrentDevice] = useState<any>({})
    const [isLoading, setIsLoading] = useState(true);
    // const [CreatedAt, setCreatedAt] = useState("");
    const [IsEditMode,setIsEditMode] = useState(false);
    const patternValue = /^[ A-Za-z0-9_+-]*$/
    const alphanumericPattern = "^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$"
    const handleClose = () => setShow(false);
    const [makeAlertShow, setMakeAlertShow] = useState(false);
    const hideShowConfigModel = () => {
        setShowConfig(false);
        setShow(true);
    }
    const handleAlertConfirm = () => {
        setMakeAlertShow(false);
    }
    const handleAlertCancel = () => {
        setMakeAlertShow(false);
        if(currentDevice && currentDevice.id){
            setValue("make", currentDevice.make);
        }
    }
    
    const resetConfig = async () => {
        setLayoutErrorMsg("");
        setValidationMsg("");
        let res: any = await getData('/layout-template/'+deviceMake);
        if (res.status == 200){
            console.log("config api data: ", res.data);
            let list:any = [];
            if(res && res.data){
                let keysList = Object.keys(res.data);
                if(res.data && keysList.length>0){
                    keysList.forEach((element:any)=>{
                        const newRow: deviceConfig = {
                            slot: Number(element),
                            count: res.data[element][0],
                            price: res.data[element][1],
                            name: res.data[element][2],
                        };
                        list.push(newRow);
                    });
                }
            }
            setData(list);
            setOriginalData(list);
            setShowConfig(true);
            setShow(false);
        }
    }
    const { register, handleSubmit, setValue, watch, formState: { errors } }: any = useForm(
        {
            mode: "onChange",
        }
    );
    const handleDeviceMakeChange = (event:any) => {
        setDeviceMake(event.target.value);
        if(currentDevice && currentDevice.id){
            setMakeAlertShow(true);
        }
    };

    const handleDeviceNameChange = (event:any) => {
        setDeviceName(event.target.value);
    };

    //editable table  
    type deviceConfig = {
        slot: number;
        price: number;
        count: number;
        name: string;
      };
    const defaultData: deviceConfig[] = []
    //   const defaultData: deviceConfig[] = [
    //     {
    //         slot: 1,
    //         price: 120,
    //         count: 10,
    //         name: "Kings",
    //     },
    //     {
    //       slot: 12,
    //       price: 240,
    //       count: 10,
    //       name: "Marlbaoro",
    //     },
    //     {
    //         slot: 13,
    //         price: 310,
    //         count: 10,
    //         name: "Black",
    //       }
    //   ];
    const saveConfig = () => {
        let index = 0;
        let isExist = false;
        const isAlphaNumeric = (str:any) => /^[a-z0-9. ]*$/gi.test(str);
        data.forEach((rec:any)=>{
            if(data[index]['slot'].toString() == "" || data[index]['slot']< 0 || data[index]['slot'] > 999){
                setValidationMsg("Enter Slot value between 0-999");
                isExist = true;
            }
            else if(data[index]['price'].toString() == "" || data[index]['price'] < 0 || data[index]['price'] > 9999){
                setValidationMsg("Enter Price value between 0-9999");
                isExist = true;
            }
            else if(data[index]['count'].toString() == "" || data[index]['count'] < 0 || data[index]['count'] > 99){
                setValidationMsg("Enter valid Count. Value between 0-99");
                isExist = true;
            }
            else if(data[index]['name'] == ""){
                setValidationMsg("Name field allows upto 100 character, alphaNumeric, dot and space only");
                isExist = true;
            }
            else if(data[index]['name'].length > 100){
                setValidationMsg("Name field allows upto 100 character, alphaNumeric, dot and space only");
                isExist = true;
            }
            else if(!isAlphaNumeric(data[index]['name'])){
                setValidationMsg("Name allowed AlphaNumeric, dot and Space only");
                isExist = true;
            }
            index++;
        });
        if(!isExist){
            setShowConfig(false);
            setShow(true);
            if(IsEditMode){
                let layoutData:any = {};
                originalData.forEach((config:any)=>{
                    let slot_value = "";
                    if(config.slot<10){
                        slot_value = "0"+ config.slot.toString();
                    }
                    else{
                        slot_value = config.slot.toString();
                    }
                    let config_Value = [];
                    config_Value.push(Number(config.count));
                    config_Value.push(Number(config.price));
                    config_Value.push(config.name);
                    layoutData[slot_value] = config_Value;
                });
                deviceList[currentDevice['tableData']['id']]["layout"] = layoutData;
            }
        }
        // setShowConfig(false);
        // setShow(true);
    };

      const EditCell = ({ row, table } : ({ row:any, table: any})) => {
        const meta = table.options.meta;
        const setEditedRows = (e: MouseEvent<HTMLButtonElement>) => {
            const elName = e.currentTarget.className;
            if(meta && elName == "device-config-success"){                
                if(row['original']['slot'].toString() == "" || row['original']['slot']< 0 || row['original']['slot'] > 999){
                    setValidationMsg("Enter Slot value between 0-999");
                    return false;
                }
                let isExist = false;
                Object.keys(data).forEach((element:any)=>{
                    if(element != row.id && Number(data[element].slot) == Number(row["original"]["slot"])){
                        isExist = true;
                        setValidationMsg("Slot already exist");
                    }
                });
                if(isExist)
                    return false;

                const isAlphaNumeric = (str:any) => /^[a-z0-9. ]*$/gi.test(str);
                if(row['original']['price'].toString() == "" || row['original']['price'] < 0 || row['original']['price'] > 9999){
                    setValidationMsg("Enter Price value between 0-9999");
                    return false;
                }
                else if(row['original']['count'].toString() == "" || row['original']['count'] < 0 || row['original']['count'] > 99){
                    setValidationMsg("Enter Count value between 1-99");
                    return false;
                }
                else if(row['original']['name'].trim() == ""){
                    setValidationMsg("Name field allows upto 100 character, alphaNumeric, dot and space only");
                    return false;
                }
                else if(row['original']['name'].trim().length > 100){
                    setValidationMsg("Name field allows upto 100 character, alphaNumeric, dot and space only");
                    return false;
                }
                else if(!isAlphaNumeric(row['original']['name'].trim())){
                    setValidationMsg("Name allowed AlphaNumeric, dot and Space only");
                    return false;
                }

            }
            meta?.setEditedRows((old: []) => ({
            ...old,
            [row.id]: !old[row.id],
            }))
            if (elName !== "edit") {
                setValidationMsg("");
                meta?.revertData(row.index, e.currentTarget.className === "cancel")
              }
        };
        const removeRow = () => {
            meta?.removeRow(row.index);
        };
        return meta?.editedRows[row.id] ? (
            <>
            <span className="device-config-success" onClick={setEditedRows}>✔</span>{" "}
            <span className="cancel" onClick={setEditedRows} >X</span>
            </>
        ) : (
            <div>
            <span className="device-config-edit" onClick={setEditedRows}>✐</span>{" "}
            <span onClick={removeRow} className="remove">X</span></div>
        )
    }
    
      const TableCell = ({ getValue, row, column, table }:{getValue:any, row:any, column:any, table:any}) =>  {
        const initialValue = getValue()
        const tableMeta = table.options.meta
        const [value, setValue] = useState(initialValue)
        useEffect(() => {
            setValue(initialValue)
        }, [initialValue])
        const onBlur = () => {
            table.options.meta?.updateData(row.index, column.id, value)
        }
        if (tableMeta?.editedRows[row.id]) {
            return (
                <input
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                type={column.columnDef.meta?.type || "text"}
                />
            )
        }
        return <span>{value}</span>
    };
    
    const FooterCell = ({ table } : { table: any}) => {
        const meta = table.options.meta
        return (
            
          <div className="footer-buttons">
            <span className="add-button config-save-icon" style={{"border": "1px solid gray", display: originalData.length>0 ? 'block' : 'none'}} onClick={saveConfig}>
              Save
            </span>
            <span className="add-button config-Add-icon" onClick={meta?.addRow}>
              Add New +
            </span>
          </div>
        )
      }
    
      const columnHelper = createColumnHelper<deviceConfig>();
      const columns = [
        columnHelper.accessor("slot", {
            header: "Slot",
            cell: TableCell,
            meta: {
            type: "number",
            },
        }),
        columnHelper.accessor("price", {
            header: "Price",
            cell: TableCell,
            meta: {
            type: "number",
            },
        }),
        columnHelper.accessor("count", {
            header: "Count",
            cell: TableCell,
            meta: {
            type: "number",
            },
        }),
        columnHelper.accessor("name", {
            header: "Name",
            cell: TableCell,
            meta: {
            type: "text",
            },
        }),
        columnHelper.display({
            id: "edit",
            cell: EditCell,
          }),
        ]
    //   export const Table = () => {
        const [data, setData] = useState(() => [...defaultData]);
        const [originalData, setOriginalData] = useState(() => [...defaultData]);
        const [editedRows, setEditedRows] = useState({});
        const table = useReactTable({
            data,
            columns,
            getCoreRowModel: getCoreRowModel(),
            meta: {
                editedRows,
                setEditedRows,
                revertData: (rowIndex: number, revert: boolean) => {
                    if (revert) {
                      setData((old) =>
                        old.map((row, index) =>
                          index === rowIndex ? originalData[rowIndex] : row
                        )
                      );
                    } else {
                      setOriginalData((old) =>
                        old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
                      );
                    }
                  },
              updateData: (rowIndex: number, columnId: string, value: string) => {
                setData((old) =>
                  old.map((row, index) => {
                    if (index === rowIndex) {
                      return {
                        ...old[rowIndex],
                        [columnId]: value,
                      };
                    }
                    return row;
                  })
                );
              },
              addRow: () => {
                let limit = 60;
                if(data.length<limit){
                    const newRow: deviceConfig = {
                        slot: 0,
                        price: 0,
                        count: 0,
                        name: "",
                      };
                      const setFunc = (old: deviceConfig[]) => [...old, newRow];
                      setData(setFunc);
                      setOriginalData(setFunc);    
                }
                else{
                    setValidationMsg("You created maximum number of records");
                }                
              },
              removeRow: (rowIndex: number) => {
                setValidationMsg("")
                const setFilterFunc = (old: deviceConfig[]) =>
                  old.filter((_row: deviceConfig, index: number) => index !== rowIndex);
                setData(setFilterFunc);
                setOriginalData(setFilterFunc);
              },
            },
          });
      
        

    //End editable table
    const showConfigModel = async (cdevice:any) => {
        console.log("showConfigModel: ", cdevice);
        if(currentDevice && currentDevice.name){
            console.log("showConfigModel-data: ", data);
            let list:any = [];
            if(cdevice && cdevice?.layout){
                console.log("showConfigModel-2: ", cdevice);
                let keysList = Object.keys(cdevice?.layout);
                keysList.forEach((element:any)=>{
                    const newRow: deviceConfig = {
                        slot: Number(element),
                        count: cdevice.layout[element][0],
                        price: cdevice.layout[element][1],
                        name: cdevice.layout[element][2],
                      };
                    list.push(newRow);
                });
                list.sort(function(a:any, b:any){
                    return a.slot - b.slot;
                });
            }
            setData(list);
            console.log("showConfigModel-List: ", list);
            setOriginalData(list);
            setShowConfig(true);
            setShow(false);
            console.log("showConfigModel-originalData: ", originalData);
        }
        else{
         let isValidation = true;
         if(deviceName == ""){
            setLayoutErrorMsg("Enter device name");
            isValidation = false;
         }
         else if(deviceMake == ""){
            setLayoutErrorMsg("Select make");
            isValidation = false;
         }
         if(isValidation){
            setLayoutErrorMsg("");
            let res: any = await getData('/layout-template/'+deviceMake);
            if (res.status == 200){
                console.log("NEw config api data: ", res.data);
                let list:any = [];
                if(res && res.data){
                    let keysList = Object.keys(res.data);
                    if(res.data && keysList.length>0){
                        keysList.forEach((element:any)=>{
                            const newRow: deviceConfig = {
                                slot: Number(element),
                                count: res.data[element][0],
                                price: res.data[element][1],
                                name: res.data[element][2],
                            };
                            list.push(newRow);
                        });
                    }
                }
                setData(list);
                setOriginalData(list);
                setShowConfig(true);
                setShow(false);
            }
         }
        }
    }
    const editDevice = (device: any) => {
        try{
            setDeviceName("");
            setDeviceMake("");
            setValidationMsg("");
            setLayoutErrorMsg("");
            if(device && device.name){
                if(device.created_at){
                    const date = new Date(device.created_at);
                    setValue("created_at", date.toLocaleString('en-IN').replace(',',''));
                }
                else{
                    setValue("created_at", "");
                }
                setIsEditMode(true);
                setCurrentDevice(device);
                setValue("name", device.name);
                setDeviceName(device.name);
                setDeviceMake(device.make);
                setValue("location", device.location)
                setValue("make", device.make)
                setValue("machine_type", device.machine_type)
                setValue("cards", device.cards);
                setValue("config", JSON.stringify(device.config));
            }
            else{
                setIsEditMode(false);
                setCurrentDevice(device);
                setValue("name", "")
                setValue("location", "")
                setValue("make", "")
                setValue("machine_type", "")
                setValue("cards", );
                setValue("config", "");
            }            
            setShow(true);
        }
        catch(error){
            setIsLoading(false);
            console.log("Exception: editDevice method invoked-Error: ",error);
        }
    }

    const generateStatusView = (status: any) => {
        if (status === "up") {
            return (<>
                <div style={{ width: "60px", backgroundColor: "#008000", textAlign: "center", color: "#ffffff", borderRadius: "5px" }}>Up</div>
            </>)
        }
        else {
            return (<>
                <div style={{ width: "60px", backgroundColor: "#f00000", textAlign: "center", color: "#ffffff", borderRadius: "5px" }}>Down</div>
            </>)
        }
    }

    const generateConfigView = (row: any) => {
        if (row.config) {
            return (<>
                <div>{JSON.stringify(row.config)}</div>
            </>)
        }
        else {
            return (<>
                <div></div>
            </>)
        }
    }

    const deletDevice = (device: any) => {
        let index: number = device.tableData.id;
        Swal.fire({
            title: `Do you want to delete ${device.name}?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Don't Delete`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let res: any = await deleteData('/device/' + device.id);
                if (res.status == 200) {
                    let tmp = deviceList;
                    tmp = tmp.splice(index, 1);
                    setDeviceList(tmp);
                    setdownloadList(tmp);
                    loadData()
                    Swal.fire('Deleted!', '', 'success')
                }
                else
                    Swal.fire('Somthing went wrong', '', 'error')
            } else if (result.isDenied) {
                Swal.fire('Delete cancelled', '', 'info')
            }
        })
    }
    const onSubmit = async (data: any) => {
        if (currentDevice && currentDevice.id) {
            delete (data['name'])
            delete (data["created_at"])
            data['id'] = currentDevice['id'];
            if(isSadmin(props.user)){
                data['config'] = JSON.parse(data['config']);
            }
            if(isLayoutConfigEnabled(props.user)){
                let layoutData:any = {};
                originalData.forEach((config:any)=>{
                    let slot_value = "";
                    if(config.slot<10){
                        slot_value = "0"+ config.slot.toString();
                    }
                    else{
                        slot_value = config.slot.toString();
                    }
                    let config_Value = [];
                    config_Value.push(Number(config.count));
                    config_Value.push(Number(config.price));
                    config_Value.push(config.name);
                    layoutData[slot_value] = config_Value;
                });
                data['layout'] = layoutData;
            }
            Object.keys(makeList).map((key: any) => {
                if (data['make'] == makeList[key]) {
                    data['make'] = key + ':' + makeList[key]
                }
            })
        }
        else {
            data["created_at"] = new Date();
            if(data['config'] != "")
                data['config'] = JSON.parse(data['config']);
            if(isLayoutConfigEnabled(props.user)){
                let layoutData:any = {};
                originalData.forEach((config:any)=>{
                    let slot_value = "";
                    if(config.slot<10){
                        slot_value = "0"+ config.slot.toString();
                    }
                    else{
                        slot_value = config.slot.toString();
                    }
                    let config_Value = [];
                    config_Value.push(Number(config.count));
                    config_Value.push(Number(config.price));
                    config_Value.push(config.name);
                    layoutData[slot_value] = config_Value;
                });
                data['layout'] = layoutData;
            }
            delete (data['id'])
        }

        let res
        if (currentDevice && currentDevice.id) {
            // let list_res: any = await getData('/list_makes');
            // if (list_res.status == 200 && Array.isArray(list_res.data))

            let update_res:any = await postData('/dev_update', data);
            loadData();
            if (update_res && update_res.status == 200){
                Swal.fire({
                    title: `Successfully Updated`,

                }).then(async (result) => {
                    setShow(false);
                });
            }
            else{
                Swal.fire({
                    title: `Failed to update device`,
        
                }).then(async (result) => {
                    // setShow(false);
                });
            }
        }
        else {
            // Check device name to avoid duplicates
            let deviceNames:any = [];
            deviceList.filter(function(data:any){
                deviceNames.push(data.name);
            });
            if(deviceNames.indexOf(data.name) == -1){
                res = await postData('/device/new', data);
                loadData();
                Swal.fire({
                    title: `Successfully saved`,

                }).then(async (result) => {
                    setShow(false);
                });
            }
            else{
                Swal.fire({
                    title: `Device name is already exist`,
        
                }).then(async (result) => {
                    // setShow(false);
                });
            }
        }
    }

    useEffect(() => {
        loadData();
    }, [])
    const loadData = async () => {
        setValidationMsg("");
        setShow(false);
        setShowConfig(false);
        let isCookieAvailable: any = await getSessionDetails();
        if(!isCookieAvailable){
            props.dispatch(logout());
        }
        setIsLoading(true)
        let res: any = await getData('/devices');
        let list_res: any = await getData('/list_makes');
        if (res.status == 200 && Array.isArray(res.data))
            setDeviceList(res.data);
            setdownloadList(res.data);
        if (list_res.status == 200)
            setMakeList(list_res.data);
        setIsLoading(false);

        // let ulist: any = await getData('/ulist');
    }

    const downloadCSV = async () => {
        try{
            setIsLoading(true);
            let dList : any = [] ;
            if(downloadList.length>0){
                downloadList.forEach((element:any) => {
                    let current_rec : any = {};
                    if(element['status'] && element['status'] == 'up'){
                        current_rec['status'] = "Up";    
                    }
                    else{
                        current_rec['status'] = "Down";
                    }
                    current_rec['cash_txn'] = "₹ "+element['cash_txn'].toLocaleString();
                    if(element['created_at']){
                        const date = new Date(element['created_at']);
                        current_rec['created_at'] = date.toLocaleString('en-IN').replace(',','');
                    }
                    else{
                        current_rec['created_at'] = "";
                    }
                    
                    current_rec['customer'] = element['customer'];
                    current_rec['location'] = element['location'];
                    current_rec['mac'] = element['mac'];
                    current_rec['machine_type'] = element['machine_type'];
                    current_rec['make'] = element['make'];
                    current_rec['name'] = element['name'];
                    if(element['config']){
                        current_rec['config'] = JSON.stringify(element['config']);
                    }
                    if(element['cards'] === true){
                        current_rec["cards"] = "Yes";
                    }
                    else{
                        current_rec["cards"] = "";
                    }
                    if(element['version']){
                        current_rec['version'] = element['version']
                    }
                    else{
                        current_rec['version'] = ""
                    }
                    dList.push(current_rec);
                });
                var myFile = "Device_List.xlsx";
                var myWorkSheet = XLSX.utils.json_to_sheet(dList);
                var myWorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "SHEET1");
                XLSX.writeFile(myWorkBook, myFile);
                setIsLoading(false);
            }
        }
        catch(error){
            setIsLoading(false);
            console.log("Exception: Download method invoked-Error: ",error);
        }

    }

    const DownloadConfigLayout = async () => {
        try{
            setIsLoading(true);
            let dList : any = [] ;
            if(originalData.length>0){
                Object.keys(originalData).forEach((element:any) => {
                    console.log("Element: ", element);
                    console.log("Element data: ", originalData[element]);
                    let current_rec : any = {};
                    if(originalData[element]){
                        current_rec['slot'] = originalData[element].slot;
                        current_rec['price'] = originalData[element].price;
                        current_rec['count'] = originalData[element].count;
                        current_rec['name'] = originalData[element].name;
                        dList.push(current_rec);
                    }                    
                });
                var today = new Date();
                const dd = String(today.getDate() + 0).padStart(2, "0");
                const mm = String(today.getMonth() + 1).padStart(2, "0");
                const yyyy = today.getFullYear();
                const hr = today.getHours();
                const mins = today.getMinutes();
                // const sec = today.getHours();
                var myFile = "dev_"+deviceName+"_Layout_"+yyyy+mm+dd+"-"+hr+"-"+mins+".xlsx";
                var myWorkSheet = XLSX.utils.json_to_sheet(dList);
                var myWorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "SHEET1");
                XLSX.writeFile(myWorkBook, myFile);
                setIsLoading(false);
            }
        }
        catch(error){
            setIsLoading(false);
            console.log("Exception: Download method invoked-Error: ",error);
        }

    }

    const deviceColumns = [
        {
            title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1,
            headerStyle: { width: "3%" }, cellStyle: { width: "3%", maxWidth: "3%", overflow: "hidden" }, width: "3%"
        },
        {
            title: 'Status', field: 'status', render: (rowData: any) => generateStatusView(rowData.status),
            headerStyle: { width: "3%" }, cellStyle: { width: "3%", maxWidth: "3%", overflow: "hidden" }, width: "3%"
        },
        { title: 'Name', field: 'name', headerStyle: { width: "3%" }, cellStyle: { width: "3%", maxWidth: "3%", overflow: "hidden" }, width: "3%" },
        { title: 'Last Seen', field: 'lastseen', headerStyle: { width: "22%" }, cellStyle: { width: "22%", maxWidth: "22%", overflow: "hidden" }, width: "22%" },
        { title: 'Amount', field: 'cash_txn', render:(rowdata:any)=> "₹ "+rowdata.cash_txn.toLocaleString(), headerStyle: { width: "3%" }, cellStyle: { width: "3%", maxWidth: "3%", overflow: "hidden" }, width: "3%" },
        { title: 'Location', field: 'location', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Machine Type', field: 'machine_type', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Make', field: 'make', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Mac', field: 'mac', render: (rowData: any) => rowData.mac == 'null' ? "" : rowData.mac, headerStyle: { width: "3%" }, cellStyle: { width: "3%", maxWidth: "3%", overflow: "hidden" }, width: "3%" },
        { title: 'Version', field: 'version', render: (rowData: any) => rowData.version == 'null' ? "" : rowData.version, headerStyle: { width: "3%" }, cellStyle: { width: "3%", maxWidth: "3%", overflow: "hidden" }, width: "3%" },
        { title: 'Cards', field: 'cards', render: (rowData: any) => rowData.cards?"Yes":"", headerStyle: { width: "2%" }, cellStyle: { width: "2%", maxWidth: "2%", overflow: "hidden" }, width: "2%" }
        // { title: 'Config', field: 'config', render: (rowData: any) => generateConfigView(rowData), headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
    ]
    return (

        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Devices</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Devices</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3 py-4-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="stats-icon" style={{ background: "#0d6efd" }}>
                                        <i className="iconly-boldAdd-User"></i>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Total Count</h6>
                                    <h6 className="font-extrabold mb-0">{deviceList.length}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3 py-4-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="stats-icon" style={{ background: "#008000" }}>
                                        <i className="iconly-boldAdd-User"></i>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Up Count</h6>
                                    <h6 className="font-extrabold mb-0">{deviceList.filter(function (element: any) {
                                        return element.status == 'up';
                                    }).length}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3 py-4-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="stats-icon" style={{ background: "#f00000" }}>
                                        <i className="iconly-boldAdd-User"></i>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Down Count</h6>
                                    <h6 className="font-extrabold mb-0">{deviceList.filter(function (element: any) {
                                        return element.status == 'down';
                                    }).length}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3 py-4-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="stats-icon" style={{ background: "#607080" }}>
                                        <i className="iconly-boldAdd-User"></i>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Blocked</h6>
                                    <h6 className="font-extrabold mb-0">0</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row text-end device-btn-sec'>
                <Button className='Btn' variant="primary"  onClick={downloadCSV} style={{display:deviceList.length>0 ? "block":"none"}}>Download</Button>
                <Button className='Btn' variant="primary" onClick={loadData} >Refresh</Button>
                {isSadmin(props.user) ? <Button className='Btn' variant="primary" onClick={() => editDevice({})} >New</Button> : null}                
            </div>
            <section className="section">
                <div style={{ width: "100%" }}>
                    <MaterialTable title=""
                        data={deviceList}
                        columns={deviceColumns}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => editDevice(rowData),
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                onClick: (event, rowData) => deletDevice(rowData),
                                disabled: isSadmin(props.user)?false:true
                            }
                        ]}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color:"#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            columnResizable: true,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>
            </section>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        <span style={{display:IsEditMode ? "block":"none"}}>Edit Device</span>
                        <span style={{display:!IsEditMode ? "block":"none"}}>New Device</span>
                            </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <div className="modal-body">
                                <label>Name: </label>
                                <div className="form-group">

                                    <input type="text" disabled={IsEditMode ? true : false} placeholder="Name"
                                        className="form-control"  {...register("name", {

                                            pattern: {
                                                value: patternValue,
                                                message: "Special charecter not allowd"
                                            }
                                        })} onChange={handleDeviceNameChange} />
                                    {errors.name ? <span className='text-danger'>{errors.name?.message}</span> : ''}
                                </div>

                                <label style={{ display: IsEditMode ? 'none' : 'block' }}>Registry: </label>
                                <div style={{ display: IsEditMode ? 'none' : 'block' }} className="form-group">

                                    <input type="text" d placeholder="customer"
                                        className="form-control"  {...register("customer", {

                                            pattern: {
                                                value: patternValue,
                                                message: "Special charecter not allowd"
                                            }
                                        })} />

                                    {errors.customer ? <span className='text-danger'>{errors.customer?.message}</span> : ''}
                                </div>


                                <label>Location: </label>
                                <div className="form-group">

                                    <input type="text" placeholder="Location"
                                        className="form-control"  {...register("location", {

                                            pattern: {
                                                value: patternValue,
                                                message: "Special charecter not allowd"
                                            }
                                        })} />

                                    {errors.location ? <span className='text-danger'>{errors.location?.message}</span> : ''}
                                </div>
                                <label>Machine Type: </label>
                                <div className="form-group">
                                    <input type="text" placeholder="Machine Type"
                                        className="form-control" {...register("machine_type", {
                                            pattern: {
                                                value: patternValue,
                                                message: "Special charecter not allowd"
                                            }
                                        })} />

                                    {errors.machine_type ? <span className='text-danger'>{errors.machine_type?.message}</span> : ''}
                                </div>

                                <label>Make: </label>
                                <div className="form-group">
                                    <select className="form-control"  {...register("make", {

                                        pattern: {
                                            value: patternValue,
                                            message: "Special charecter not allowd"
                                        }
                                    })} onChange={handleDeviceMakeChange} >
                                        {Object.keys(makeList).map((key: any) => (
                                            <option
                                                key={key}
                                                value={makeList[key]}
                                            >
                                                {makeList[key]}
                                            </option>
                                        ))}
                                    </select>

                                    {errors.make ? <span className='text-danger'>{errors.make?.message}</span> : ''}
                                </div>
                        
                            {isSadmin(props.user) ? <span><label>Config: </label><div className="form-group">
                                <textarea type='textarea' rows="4" cols="50" className="form-control"  {...register("config", { 
                                            pattern: {
                                                value: alphanumericPattern,
                                                message: "Alpha numeric vaues only"
                                            }
                                        })} >
                                            </textarea>
                                </div></span> : null}

                                <label style={{display:IsEditMode ? "block":"none"}}>Created on: </label>
                                 <div className="form-group" style={{display:IsEditMode ? "block":"none"}}>
                                    <input type="text" disabled={true} className="form-control" {...register("created_at")} />
                                </div>

                                <label>Contactless Card: </label>
                                <div className="contactless-card-sec">
                                    <input type="checkbox" className="contactless-card"  {...register("cards")} />
                                </div>
                                <br/>
                                {isLayoutConfigEnabled(props.user) ? <label onClick={() => showConfigModel(currentDevice)} className='update-layout'>
                                        <b>Update Layout</b>
                                    </label>  : null }
                                <span style={{"color":"red", "marginLeft": "10px"}}>{layoutErrorMsg}</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </section>

            <section id="form-and-scrolling-components">
                {/* <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal> */}

                <Modal show={showConfig} onHide={hideShowConfigModel}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        <span>Layout</span>
                        <span> {deviceName} </span>
                        <span title="Reset" style={{display:IsEditMode ? "inline-block":"none"}} className='config-reset-icon' onClick={resetConfig}>Reset</span>
                        <span title="Download" style={{display:IsEditMode ? "inline-block":"none","padding": "0px 10px","verticalAlign": "top"}} className='config-download-icon' onClick={DownloadConfigLayout}>
                        <i className="bi bi-download" style={{ verticalAlign: "middle" }}></i>
                        </span>
                        </Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body>
                        <>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3} align="right">
                <span className='validationMsg-total'>{validationMsg}</span>
            </th>
            <th colSpan={2} align="right">
                <span><FooterCell table={table} /></span>
            </th>
          </tr>
        </tfoot>
      </table>
      {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
    </>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>
                                Save Changes
                            </Button>
                        </Modal.Footer> */}
                    </form>
                </Modal>
            </section>

            <section id="form-and-scrolling-components">
                <Modal show={makeAlertShow}>
                <Modal.Header>
                    <Modal.Title>
                        Alert!
                    </Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body">
                            <label>This will change selection layout.</label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleAlertCancel}>
                                Cancel
                        </Button>
                        <Button variant="primary"  onClick={handleAlertConfirm}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>

        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(Devices); 